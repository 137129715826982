@import "../../assets/styles/variables";
.allContainer{
    width: 100%;
    padding: 40px 1.4%;
    margin-bottom: 30px;
    // margin-top: -50px;

    form{
        background-color: $white-color;
        color: $gray-color;
        padding: 50px;
        border-radius: 20px;

        h2{
            font-size: 30px;
            margin-bottom: 35px;
        }

        label{
            font-size: 16px;
            font-weight: 500;
        }

        input{
            margin-top: 10px;
            border: 2px solid $gray-color;
            font-size: 16px;
            padding: 15px;
            border-radius: 15px;
            color: $gray-color;
            font-weight: 400;
        }

        textarea{
            margin-top: 10px;
            border: 2px solid $gray-color;
            font-size: 16px;
            padding: 15px;
            border-radius: 15px;
            color: $gray-color;
            font-weight: 400;
        }

        .imageContainer{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column; 
            justify-content: center;
            padding: 30px 0;
        
            h3{
                font-weight: 400;
                font-size: 20px;
            }

            .avatarContainer{
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                margin-top: 20px;

                .oneAvatar{
                    cursor: pointer;
                    display: flex;
                    width: fit-content;
                    position: relative;
                    width: 65px;
                    height: 65px;
                    border-radius: 100px;
                    background-color: $font-color;
                    overflow: hidden;
                    border: 2px solid $white-color;
                    box-shadow: 0px 0px 5px rgb(197, 197, 197);
                    
                    img{
                        object-fit: cover;
                    }

                    div{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: rgba(0, 0, 0, 0.342);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg{
                            font-size: 20px;
                            color: $white-color;
                        }
                    }
                    
                }
            }
        
        }

        .btnContainer{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            button{
                background-color: $gray-color;
                color: $white-color;
                font-size: 16px;
            }

            a:nth-child(2){
                button{
                    background-color: red;
                }
            }
        }


        .noteContainer{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;

            label{
                font-size: 20px;
            }

            .starContent{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
        
                svg{
                    cursor: pointer;
                    font-size: 30px;
                }
            }
        }

    }

    .MessageFeedBackError{
        margin-top: 25px;
        border-radius: 5px;
        width: fit-content;
        padding: 15px 20px;
        color: $white-color;
        background-color: red;
        font-size: 18px;
        font-weight: 500;
    }

    @media screen and (max-width: 500px) {
        // padding: 0px 30px;
        form{
            padding: 30px;
            h2{
                text-align: center;
                font-size: 22px;
            }

            .noteContainer{
                margin-bottom: 0px;
                label{
                    font-size: 17px;
                }
            }
        }
    }
}
