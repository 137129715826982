.allContainer{
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;

    h2{
        font-weight: 200;
        font-size: 35px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 10px;
        text-shadow: 2px 2px 20px black;
    }

    .ImgPremierePlan{
        width: 100%;
        height: 420px;
        object-fit: cover;
        position: absolute;
        top: 70px;
        left: 0px;
        z-index: 0;
    }

    .opacityBlack{
        width: 100%;
        height: 420px;
        object-fit: cover;
        position: absolute;
        top: 70px;
        left: 0px;
        background-color: black;
        opacity: 0.5;
        z-index: 1;
    }

    .TextContent{
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: fit-content;
        z-index: 2;
        padding-top: 10%;

        img{
            width: 150px;
        }

        p{
            margin: 0;
            font-weight: 400;
            font-size: 20px;
            line-height: 35px;
            letter-spacing: 1px;
        }
    }

    @media screen and (max-width: 750px) {
        padding: 30px;
        height: 50vh;

        .ImgPremierePlan, .opacityBlack{
            height: 50vh;
        }

        h2{
            font-size: 30px;
        }

        .TextContent{
            padding-top: 20%;
            img{
                width: 100px;
            }

            p{
                font-weight: 300;
                font-size: 20px;
            }
        }
    }

    @media screen and (max-width: 420px) {
        h2{
            font-size: 30px;
        }

        .TextContent{
            p{
                font-size: 16px;
            }
        }
    }
}