@import "./variables";

.p-5 {
    padding: 5px;
}
  
.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.p-30 {
    padding: 30px;
}

.px-100{
    padding-left: 100px;
    padding-right: 100px;
}

.m-5 {
    margin: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.m-10 {
    margin: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.m-20 {
margin: 20px;
}

.mt-40{
    margin-top: 40px;
}

.mb-20 {
    margin-bottom: 20px;
}

.my-30 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.mx-100{
    margin-left: 100px;
    margin-right: 100px;
}

.container {
    margin: auto;
    width: 100%;
}

.containerStyles{
    width: 85%;
    background-color: $white-color;
    box-shadow: 0px 0px 8px $gray-color;
    border-radius: 20px;
    padding: 20px 25px;
    font-size: 15px;
    font-weight: 300;
    margin: 20px;
    line-height: 25px;

    h2{
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 10px;
    }
}

.callbackMessageContainer{
    margin: -5px 0px 10px 0px;
    p{
        margin: 0;
        padding: 0px;
        font-size: 14px;
    }
    .succes{
        color: green;
    }
    .failed{
        color: red;
    }
}


.message{
    &Succes, &Error{
       position: fixed;
       top: 10px;
       left: 8%;
       width: 84%;
       min-height: 55px;
       border-radius: 10px;
       text-align: center;
       font-size: 15px;
       color: $white-color;
       padding: 5px;
       padding-right: 20px;
       z-index: 10;
       display: flex;
       justify-content: space-around;
       align-items: center;
       animation: fadin-bottom 5s ease-in-out both;
       
       p{
           padding: 0;
           cursor: pointer;
       }
       div{
        padding: 5px 10px;
       }
   }
   &Error{
       background-color: red;
   }
   &Succes{
       background-color: green;
   }
}

.messageFeedBack{
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
}


@keyframes fadin-bottom {
    0%{
        transform: translateY(-100px);
    }

    10%{
        transform: translateY(0px);
        display: flex;
    }

    90%{
        transform: translateY(0px);
        display: flex;
    }

    100%{
        transform: translateY(-100px);
        display: none;
    }
}

.error{
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $text-color;

    h1{
        margin: 0;
        font-size: 100px;
        font-weight: 700;
    }

    p{
        font-size: 16px;
        padding: 0px 30px;
        text-align: center;
    }
}


.titlePage{
    font-weight: 500;
    font-size: 20px;
    background-color: $text-color;
    color: $text-color;
    width: 100%;
    padding: 85px 30px 25px 30px;

    @media screen and (min-width: 650px) {
        font-size: 30px;
        padding: 30px 10%;
        padding-top: 95px;
    }
    @media screen and (max-width: 969px) {
        padding-top: 85px;
    }
}


.slick-track {
    display: flex !important;
    justify-content: space-between !important;
    position: initial !important;
    column-gap: 25px;
    @media screen and (max-width: 750px) {
        column-gap: 5px;
    }
}

.slick-arrow{
    display: none !important;
}


.link_menu{
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 13px;
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    letter-spacing: 1.2px;
    color: #C3C3C3;

    a{
        color: #C3C3C3;
        font-size: 13px;
    }

    p{
        display: none;
    }

    &:hover{
        a{
            color: white;
        }
        font-weight: 500;
        transition: all 300ms;
    }
}

.link_active{
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    letter-spacing: 1.2px;
    color: white;

    a{
        color: white;
        font-size: 13px;
    }

    p{
        color: white;
        width: 6px;
        height: 6px;
        min-width: 6px;
        min-height: 6px;
        border-radius: 100px;
        background-color: $white-color;
        margin: 0;
    }
}


.link_menu_mobile{
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    row-gap: 50px;
    letter-spacing: 1.2px;
    color: $gray-color;
    margin: 20px 0px;

    a{
        color: $gray-color;
        font-size: 13px;
    }

    p{
        display: none;
    }

    &:hover{
        a{
            color: black;
        }
        font-weight: 600;
        transition: all 300ms;
    }
}

.link_active_mobile{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    letter-spacing: 1.2px;
    color: black;
    margin: 20px 0px;

    a{
        color: black;
        font-size: 13px;
    }

    p{
        color: black;
        width: 6px;
        height: 6px;
        min-width: 6px;
        min-height: 6px;
        border-radius: 100px;
        background-color: black;
        margin: 0;
        margin-right: 10px;
    }
}

._1star{
    svg{
        &:nth-child(1){
            color: orange;
        }
    }
}

._2star{
    svg{
        &:nth-child(1){
            color: orange;
        }
        &:nth-child(2){
            color: orange;
        }
    }
}

._3star{
    svg{
        &:nth-child(1){
            color: orange;
        }
        &:nth-child(2){
            color: orange;
        }
        &:nth-child(3){
            color: orange;
        }
    }
}

._4star{
    svg{
        &:nth-child(1){
            color: orange;
        }
        &:nth-child(2){
            color: orange;
        }
        &:nth-child(3){
            color: orange;
        }
        &:nth-child(4){
            color: orange;
        }
    }
}

._5star{
    svg{
        color: orange;
    }
}