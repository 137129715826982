@import "../../assets/styles/variables";

.allContainer{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/images/image_chateau.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    h2{
        margin-top: 50px;
    }
    
    .formContainer{
        padding: 100px 25%;
        h3{
            text-align: center;
            margin-bottom: 30px;
            font-size: 30px;
            letter-spacing: 1.3px;
            font-weight: 500;
        }
        
        p{
            margin-bottom: 80px;
            text-align: center;
            font-size: 18px;
            letter-spacing: 1px;
            font-weight: 300;
        }
    
        @media screen and (max-width: 750px) {
            padding: 50px 30px;
            h3{
                font-size: 25px;
                margin-bottom: 20px;
            }

            p{
                margin-bottom: 50px;
            }
        }
    }

    .coodonneeContainer{
        padding: 80px 10%;
        padding-bottom: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .coordonnee{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: rgba(255, 255, 255, 0.20);
            flex: 0 0 330px;
            border-radius: 15px;
            padding: 35px 10px;

            .iconeContainer{
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                background-color: $white-color;
                color: $gray-color;
                border-radius: 100px;
                padding: 10px;
            }

            h4{
                font-weight: 500;
                letter-spacing: 1px;
                font-size: 17px;
            }
        }
    }
}
