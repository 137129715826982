@import "../../assets/styles/variables";

.allContainer{
    h2{
        margin-top: 50px;
    }

    .formContainer{
        padding: 100px 25%;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/images/image_de_pont.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        h3{
            text-align: center;
            margin-bottom: 80px;
            font-size: 30px;
            letter-spacing: 1.3px;
            font-weight: 500;
        }
    
        @media screen and (max-width: 750px) {
            padding: 50px 30px;
            h3{
                font-size: 25px;
                margin-bottom: 50px;
            }
        }
    }

    .AvisDrechercheContainer{
        padding: 80px 9%;
        background-color: $gray-color;
        text-align: center;
        margin-top: -5px;
    
        h3{
            text-align: center;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: -30px;
        }

        p{
            margin: 20px;
            margin-top: 45px;
            margin-bottom: 40px 0px;
            font-size: 17px;
            line-height: 28px;
        }

        a{
            width: fit-content;
            button{
                font-size: 17px;
                padding: 20px 35px;
                border: 1px solid $white-color;
                border-radius: 100px;
                letter-spacing: 1.3px;
                color: $white-color;
                background-color: transparent;
                cursor: pointer;
            }
        }
    
        @media screen and (max-width: 750px) {
            padding: 50px 30px;

           p{
                margin-bottom: 30px;
           }

           button{
            margin-bottom: -40px;
           }
        }

        @media screen and (max-width: 420px) {
            padding-bottom: 80px;
            h3{
                font-size: 25px;
            }

        }
    }

}