@import "../../assets/styles/variables";

.allContainer{
    width: 100%;
    background-color: $white-color;
    color: $gray-color;
    border-radius: 20px;
    box-shadow: 2px 2px 20px $gray-color;
    overflow: hidden;
    margin-top: 80px;
    height: 335px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}