@import "../../assets/styles/variables";

.allContainer{
    max-width: 400px;
    padding: 30px 20px;
    background-color: $white-color;
    color: $gray-color;
    border-radius: 20px;
    // box-shadow: 2px 2px 20px $gray-color;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        margin: 0;
        margin-bottom: 20px;
        overflow: hidden;
        cursor: pointer;
    }

    h3{
        text-align: center;
        font-weight: 200;
        font-size: 25px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 15px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .imgContainer{
        width: 100%;
        display: flex;
        justify-content: center;

        div{
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
            border: 3px solid $white-color;
            box-shadow: 0px 0px 2px #D9D9D9;
            background-color: #D9D9D9;
            border-radius: 100px;
            margin-bottom: 10px;
            overflow: hidden;
            
            img{
                object-fit: cover;
            }
        }
    }

    .starContent{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-top: 10px;

        svg{
            font-size: 25px;
        }
    }

}