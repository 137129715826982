@import "../../assets/styles/variables";

.scrollTotopshow, .scrollToTop{
    width: fit-content;
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: all 400ms;
    z-index: 999;
    cursor: pointer;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button{
        background-color: $white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        border: none;
        cursor: pointer;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.459);

        svg{
            font-size: 20px;
            color: $gray-color;
        }
    }

    p{
        font-weight: 200;
        margin: 10px 0px;
        font-size: 15px;
        text-shadow: 0px 0px 1px black;
    }

    &:hover{
        opacity: 0.8;
    }
}

.scrollToTop{
    visibility: hidden;
}