// @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

$font-family: 'Montserrat', sans-serif;
$text-color : #FFFF;
$white-color : #FFFF;
$font-color: #1E1E1E;
$gray-color: black;
$blue-color: blue;
$green-color: green;
$red-color: red;
$orange-color: orange;