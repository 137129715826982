@import "../../assets/styles/variables";

.allContainer{
    .firstSection{
        h2{
            margin-top: 50px;
        }
    }

    .investerSection{
        padding: 100px 10%;
        margin-top: -5px;
        background-color: $gray-color;
    
        div{
            padding: 50px;
            padding-bottom: 80px;
            background-color: $white-color;
            color: $gray-color;
            border-radius: 18px;
            
            ul{
                margin-bottom: 70px;
                padding-left: 16px;
                li{
                    list-style: initial;
                    font-size: 20px;
                    line-height: 28px;
                    margin: 20px 0px;
                }
            }
        
            h2{
                font-size: 30px;
                letter-spacing: 1.3px;
                margin-bottom: 35px;
            }
        
            a{
                width: fit-content;
                padding: 20px 35px;
                background-color: $gray-color;
                color: $white-color;
                border-radius: 100px;
                letter-spacing: 1.3px;
            }
        
            @media screen and (max-width: 750px) {
                h2{
                    font-size: 25px;
                }
        
                ul{
                    li{
                        font-size: 17px;
                    }
                }
            }
        }
    
        @media screen and (max-width: 750px) {
            padding: 50px 30px;
        }
    
        @media screen and (max-width: 420px) {
            div{
                padding: 30px;
                padding-bottom: 60px;
            }
        }
    }
    
    .BienContainer{
        padding: 50px 10%;
    
        .showFiltre{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $white-color;
            padding-bottom: 10px;
            margin-bottom: 30px;
            cursor: pointer;
            p{
                margin: 0;
            }
        }
    
        .filtreContainer{
            display: flex;
            column-gap: 20px;
    
            input{
                width: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                padding: 10px 2px;
                font-size: 16px;
                font-weight: 300;
                color: $white-color;
                border-bottom: 1px solid $white-color;
                margin-bottom: 40px;
                appearance: textfield;
    
                &::placeholder{
                    color: $white-color;
                }
            }
    
            select{
                width: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                padding: 10px 2px;
                font-size: 16px;
                font-weight: 300;
                color: $white-color;
                border-bottom: 1px solid $white-color;
                margin-bottom: 40px;
    
                option{
                    color: $gray-color;
                }
            }
    
            .btnSearchContainer{
                width: 35px;
                height: 35px;
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white-color;
                border: 1px solid $white-color;
                border-radius: 5px;
                margin-top: 10px;
                cursor: pointer;
                svg{
                    color: $gray-color;
                }
            }
    
            @media screen and (max-width: 700px) {
                flex-direction: column;
                display: none;
    
                .btnSearchContainer{
                    color: $gray-color;
                    width: fit-content;
                    span{
                        margin-right: 20px;
                    }
                }
            }
    
            @media screen and (min-width: 701px) {
                .btnSearchContainer{
                    span{
                        display: none;
                    }
                }
            }
        }
    
        .ListeDBien{
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
    
            .notFoundBien{
                display: flex;
                margin: auto;
                text-align: center;
                margin-top: 50px;
                font-size: 30px;
                font-weight: 500;
                color: rgb(80, 80, 80);
            }
        }
    
        .showMoreBtn{
            margin: 50px 0px;
            text-align: center;
            
            button{
                font-size: 18px;
                font-weight: 400;
                background-color: $white-color;
                border: 1px solid $white-color;
                padding: 15px 35px;
                border-radius: 100px;
                cursor: pointer;
            }
        }
    
        @media screen and (max-width: 750px) {
            padding: 40px 30px;
        }
    
        @media screen and (min-width: 701px) {
            .showFiltre{
                display: none;
            }
        }
    }
}

