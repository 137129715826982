.cookieConsentContainer{
    width: fit-content;
    position: relative;
    z-index: 9999;
    
    .cookieConsent{
        z-index: 9999;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        max-width : 500px;
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 20px;
        padding: 20px;
        background-color: white;
        color: black;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.137);
        border-radius: 10px;

        p{
            line-height: 25px;
            margin: 0;
        }
    
        button{
            background-color: black;
            color: white;
            font-size: 15px;
            padding: 15px 25px;
            font-weight: 500;
            // text-transform: uppercase;
            border: none;
            border-radius: 100px;
            cursor: pointer;
            margin-right: 10px;
        }
    }
}