@import "../../assets/styles/variables";

.allContainer{
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.329);
    color: $white-color;
    border-radius: 10px;
    gap: 20px;
    padding: 5px;
    cursor: pointer;
    animation: fadin-botom 1s ease-in-out both;

    .imageContainer{
        position: relative;
        max-width: 500px;
        height: 250px;
        background-color: gray;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 12px;
        flex: 1 0 40%;

        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 12px;
            filter: grayscale(1);

            &::before{
                color: transparent;
            }
        }

        .statusBienVendu, .statusSousCompromis{
            position: absolute;
            top: 10px;
            left: 10px;
            color: $white-color;
            background-color: $gray-color;
            padding: 10px 25px;
            border-radius: 100px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 15px;
        }
    }

    .detailsContainer{
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .partialCarteristique{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
        
            .oneCaractq{
                width: fit-content;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 8px;
        
                p{
                    font-weight: 300;
                    margin: 0;
                    &:nth-child(1){
                        width: 5px;
                        height: 5px;
                        min-width: 5px;
                        min-height: 5px;
                        background-color: $white-color;
                        border-radius: 100px;
                    }
                }
            }
        }

        .localisation{
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 1px;
        }

        .titleBien{
            font-size: 18px;
            letter-spacing: 1px;
        }

        .prix{
            font-size: 30px;
            letter-spacing: 1px;
            font-weight: 500;
        }
    }

    &:hover{
        transition: all 100ms;
        box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.185);
        .imageContainer{
            img{
                filter: grayscale(0);
            }

            .statusBienVendu{
                background: red;
                transform: all 200ms;
            }

            .statusSousCompromis{
                background-color: orange;
                transform: all 200ms;
            }
        }
    }

    @media screen and (max-width: 899px) {
        flex-direction: column;
        .imageContainer{
            max-width: initial;
            width: 100%;
            flex: auto;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0px;
            img{
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 0px;
            }
        }

        .prix{
            margin-top: 25px;
        }
    }
}

@keyframes fadin-botom {
    0%{
        transform: translateY(20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0px);
        opacity: 1;
    }
}