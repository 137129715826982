@import "../../assets/styles/variables";

.allContainer{
    h2:nth-child(1){
        margin-top: 50px;
    }
}

.chekedContainer{
    width: 100%;
    padding: 70px 15%;
    background-color: $gray-color;
    margin-top: -5px;

    p{
        margin: 0;
    }

    h3{
        text-align: center;
        margin-bottom: 35px;
        font-size: 30px;
        letter-spacing: 1.3px;
        font-weight: 500;
    }
    
    .ListeCheckContainer{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 10%;

        div{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            column-gap: 18px;
            margin: 18px 0px;
            flex: 1 0 40%;
            
            svg{
                font-size: 25px;
            }
    
            p{
                font-size: 17px;
                line-height: 28px;
                margin: 0;
                font-weight: 300;
                margin-top: -8px;
            }
        }
    }

    @media screen and (max-width: 750px) {
        padding: 50px 30px;

        .ListeCheckContainer{
            column-gap: 8%;
            div{
                margin: 10px 0px;
                flex: 1 0 300px;
            }
        }
    }

    @media screen and (max-width: 420px) {
        h3{
            font-size: 25px;
        }

        .ListeCheckContainer{
            div{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}

.formContainer{
    padding: 100px 25%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/images/image_chambre.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    h3{
        text-align: center;
        margin-bottom: 80px;
        font-size: 30px;
        letter-spacing: 1.3px;
        font-weight: 500;
    }

    @media screen and (max-width: 750px) {
        padding: 50px 30px;
        h3{
            font-size: 25px;
            margin-bottom: 50px;
        }
    }
}

.BienVenduContainer{
    padding: 100px 9%;
    padding-bottom: 0px;
    background-color: $gray-color;

    h3{
        text-align: center;
        font-weight: 200;
        font-size: 30px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 60px;
    }

    @media screen and (max-width: 750px) {
        padding: 50px 30px;

        h3{
            font-size: 25px;
            margin-bottom: 30px;
        }
    }
}
