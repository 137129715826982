.loadingContainer{
    background-color: rgba(255, 255, 255, 0.329);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8;
    overflow: hidden;
    img{
        width: 40px;
    }
}